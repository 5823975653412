import React from 'react';
import './Banner.scss'

import ProBeachHockey from '../../img/pbh-header.gif'

const Banner = () => (
  <header>
    <img src={ProBeachHockey} className='banner' alt=''/>
  </header>
)

export default Banner
